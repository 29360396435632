
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        




























































































@import "src/scss/variables";

span {
  $size: 10px;
  
  position: relative;
  white-space: nowrap;
  
  .v-popover {
    vertical-align: middle;
    
    ::v-deep {
      .tooltip {
        &-trigger {
          line-height: 0;
        }
      }
    }
  }
  
  .before, .after {
    .v-popover {
      vertical-align: initial;
    }
  }
  
  .before {
    .v-popover {
      padding-right: 0.4em;
    }
  }
  
  .after {
    .v-popover {
      padding-left: 0.4em
    }
  }
  
  i {
    width: $size;
    height: $size;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    background-color: transparent;
    
    &.is-success {
      background-color: $success;
    }
    
    &.is-warning {
      background-color: $warning;
    }
    
    &.is-danger {
      background-color: $danger;
    }
    
    &.is-info {
      background-color: $info;
    }
  }
}
